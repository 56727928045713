$modal-body-font-family: 'Open-Sans', sans-serif;
$modal-body-font-weight: 400;
$modal-body-color: #373d45;

@mixin scrollbar {
    & {
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $modal-body-color;
            border-radius: 10px;
            border: 2px solid #555555;
        }
    }
}

@mixin modal-text-style {
    & {
        font-family: 'Open-Sans', sans-serif !important;
        color: $modal-body-color;
    }
}

@mixin modal-btn-style {
    & {
        border-radius: 50px;
        border: none;
        margin: auto;
        font-family: 'Open-Sans', sans-serif !important;
        color: #fff;
        width: 125px;
        font-size: 16px;
        background-color: #253138;
        line-height: 40px;
        cursor: pointer;
    }
}

@mixin modal-body-text-styles {
    & {
        font-size: 16px;
        font-family: $modal-body-font-family !important;
        font-weight: $modal-body-font-weight;
        color: $modal-body-color;
        border-top: 1px solid #bfbfbf;
        border-bottom: 1px solid #bfbfbf;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

@mixin modal-inner-body-styles {
    & {
        ul {
            padding-left: 20px;
        }

        a {
            cursor: pointer;
            color: #007bff;
            text-decoration: underline;
            word-wrap: break-word;
            word-break: break-all;
            overflow-wrap: break-word;
            white-space: normal;
        }

        & .one_third_privacy {
            width: 30.3%;
            margin: 0 3%;
            display: inline-block;
            vertical-align: top;
        }

        & .two_third {
            width: 60.6%;
            display: inline-block;
        }

        &.no-title {
            h1 {
                display: none;
            }
        }

        &.no-img {
            p > img {
                display: none;
            }
        }
    }
}

@mixin modal-close-styles {
    & {
        position: relative;
        align-self: baseline;
        height: fit-content;
        padding: 0;
        font-size: 2em;
        font-weight: 900;
        border: none;
        background-color: transparent;
        opacity: 1;
        outline: none;
        cursor: pointer;
    }
}

@mixin modal-footer-styles {
    & {
        display: flex;
        padding: 0.5em;
        border-top-color: rgb(191, 191, 191);

        .modal-btn {
            @include modal-btn-style;
        }
    }
}

.modal-open {
    overflow: hidden;
}

.modal-con {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    z-index: 1000;

    h1 {
        font-size: 30px !important;

        @media screen and (min-width: 412px) {
            font-size: 32px !important;
        }
        @media screen and (min-width: 768px) {
            font-size: 36px !important;
        }
        @media screen and (min-width: 1024px) {
            font-size: 40px !important;
        }
    }
    h2 {
        font-size: 30px !important;
    }
    h3 {
        font-size: 28px !important;
    }
    h4 {
        font-size: 26px !important;
    }
    h5 {
        font-size: 24px !important;
    }
    h6 {
        font-size: 22px !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 1.2rem 0 1rem !important;
        line-height: 1.1 !important;
        font-family: $modal-body-font-family !important;
    }

    @media (orientation: landscape) {
        .modal-dialog {
            width: 95vw;
            max-width: 950px;
            max-height: 80vh;
            margin: 5vh auto;

            .modal-content {
                display: flex;
                flex-direction: column;
                width: 95%;
                max-width: 950px;
                height: 90vh;
                margin: 0 auto;
                background-color: #fff;
                border-radius: 0;
                text-align: left;

                img {
                    width: unset;
                }

                .modal-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 15px 0;
                    border-bottom-color: #bfbfbf;

                    // Large devices (portrait tablets, 1024px and up)
                    @media (orientation: portrait) and (min-width: 1020px) {
                        padding: 5px 20px 5px 55px;
                    }
                    // Medium devices (landscape tablets and desktops, 960px and up)
                    @media (orientation: landscape) and (min-width: 950px) {
                        padding: 5px 20px 5px 55px;
                    }

                    .modal-title {
                        margin: 0;
                        padding: 0;
                        animation: textFadeInUp 0.8s ease-out;
                        @include modal-text-style;
                    }

                    .close {
                        @include modal-close-styles;
                    }
                }

                .modal-body {
                    flex: 1;
                    width: 100%;
                    height: 80%;
                    padding: 15px 15px 30px 15px;
                    box-sizing: border-box;
                    line-height: initial;
                    animation: textFadeInUp 0.8s ease-out;
                    @include modal-body-text-styles;
                    @include scrollbar;
                    @include modal-inner-body-styles;

                    // remove block with WordPress generated content
                    div.wp-block-spacer {
                        display: none;
                    }

                    &.no-close {
                        margin-bottom: 20px;
                    }

                    //------------------------- Portraits: -------------------------//
                    // Small+ devices (portrait phones, 375px and up)
                    @media (orientation: portrait) and (min-width: 375px) {
                        height: 78%;
                    }
                    // Small+ devices (portrait phones, 414px and up)
                    @media (orientation: portrait) and (min-width: 410px) {
                        height: 80%;
                    }
                    // Small tablet devices (portrait tablets, 601px and up)
                    @media (orientation: portrait) and (min-width: 600px) {
                        height: 85%;
                    }
                    // Large devices (portrait tablets, 1024px and up)
                    @media (orientation: portrait) and (min-width: 1020px) {
                        height: 90%;
                        padding: 15px 55px 30px;
                    }
                    //------------------------- Landscape: -------------------------//
                    // Medium devices (landscape tablets and desktops, 960px and up)
                    @media (orientation: landscape) and (min-width: 950px) {
                        padding: 15px 55px 30px;
                    }
                    // Large devices (landscape tablets and desktops, 1024px and up)
                    @media (orientation: landscape) and (min-width: 1020px) {
                        height: 100%;
                    }
                }

                .modal-footer {
                    @include modal-footer-styles;
                }
            }
        }
    }

    @media (orientation: portrait) {
        .modal-dialog {
            max-width: 95vw;
            max-height: 80vh;
            margin: 4vh auto 16vh;
            font-size: 1em;

            .modal-content {
                display: flex;
                flex-direction: column;
                width: 95%;
                max-width: 950px;
                height: 80vh;
                margin: 0 auto;
                padding: 0;
                background-color: #fff;
                border-radius: 0;
                text-align: left;

                .modal-body {
                    box-sizing: border-box;
                    flex: 1;
                    width: 100%;
                    height: 80%;
                    padding: 15px 15px 30px;
                    line-height: 130%;
                    animation: textFadeInUp 0.8s ease-out;
                    @include scrollbar;
                    @include modal-body-text-styles;
                    @include modal-inner-body-styles;

                    @media (min-width: 962px) {
                        padding: 15px 55px 30px;
                    }

                    &.no-close {
                        margin-bottom: 20px;
                    }
                }

                .modal-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 15px;
                    border-bottom-color: rgb(191, 191, 191);

                    @media (min-width: 962px) {
                        padding: 0 55px;
                    }

                    .modal-title {
                        margin: 10px 0;
                        padding: 0;
                        line-height: 100%;
                        animation: textFadeInUp 0.8s ease-out;
                        @include modal-text-style;
                    }

                    .close {
                        @include modal-close-styles;
                    }
                }

                .modal-footer {
                    @include modal-footer-styles;
                }
            }
        }
    }

    // Small tablet devices (portrait tablets, 601px and up)
    @media (orientation: portrait) and (min-width: 600px) {
        .modal-content {
            height: 85%;
        }
    }
    // Large devices (portrait tablets, 1024px and up)
    @media (orientation: portrait) and (min-width: 1020px) {
        .modal-content {
            height: 90%;
            padding: 5px 55px;
        }
    }
}

@keyframes textFadeInUp {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
