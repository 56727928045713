@import '../../../../styles/variables';

.howplay {
    padding: 40px 0 30px;

    @media (min-width: $mobile) {
        padding: 50px 0 24px;
    }
    @media (min-width: $mobile-lg) {
        padding: 70px 0 50px;
    }
    @media (min-width: $tablet-sm) {
        padding: 110px 0 30px;
    }
    @media (min-width: $desktop-sm) {
        padding: 65px 0 30px;
    }
    @media (min-width: $desktop-lg) {
        padding: 100px 0;
    }

    &_main {
        padding-bottom: 15px;
        background:
            url('../../../../assets/howplay_pattern-360.png'),
            linear-gradient(
                    180deg,
                    rgba(149, 192, 255, 0) 0%,
                    rgba(149, 192, 255, 0.4) 16.94%,
                    rgba(149, 192, 255, 0.4) 83.33%,
                    rgba(149, 192, 255, 0) 100%
                )
                no-repeat;
        background-size: cover;

        @media (min-width: $mobile) {
            background:
                url('../../../../assets/howplay_pattern-414.png'),
                linear-gradient(
                        180deg,
                        rgba(149, 192, 255, 0) 0%,
                        rgba(149, 192, 255, 0.4) 16.94%,
                        rgba(149, 192, 255, 0.4) 83.33%,
                        rgba(149, 192, 255, 0) 100%
                    )
                    no-repeat;
            background-size: cover;
        }
        @media (min-width: $mobile-lg) {
            padding-bottom: 30px;
            background:
                url('../../../../assets/howplay_pattern-576.svg'),
                linear-gradient(
                        180deg,
                        rgba(149, 192, 255, 0) 0%,
                        rgba(149, 192, 255, 0.4) 16.94%,
                        rgba(149, 192, 255, 0.4) 83.33%,
                        rgba(149, 192, 255, 0) 100%
                    )
                    no-repeat;
            background-size: cover;
        }
        @media (min-width: $tablet-sm) {
            background:
                url('../../../../assets/howplay_pattern-768.svg'),
                linear-gradient(
                        180deg,
                        rgba(149, 192, 255, 0) 0%,
                        rgba(149, 192, 255, 0.4) 16.94%,
                        rgba(149, 192, 255, 0.4) 85.33%,
                        rgba(149, 192, 255, 0) 100%
                    )
                    no-repeat;
            background-size: cover;
        }
        @media (min-width: $desktop-sm) {
            background:
                url('../../../../assets/howplay_pattern-1024.svg'),
                linear-gradient(
                        180deg,
                        rgba(149, 192, 255, 0) 0%,
                        rgba(149, 192, 255, 0.4) 16.94%,
                        rgba(149, 192, 255, 0.4) 87.33%,
                        rgba(149, 192, 255, 0) 100%
                    )
                    no-repeat;
            background-size: cover;
        }
        @media (min-width: $desktop-lg) {
            padding-bottom: 70px;
            background:
                url('../../../../assets/howplay_pattern-1920.svg'),
                linear-gradient(
                        180deg,
                        rgba(149, 192, 255, 0) 0%,
                        rgba(149, 192, 255, 0.4) 16.94%,
                        rgba(149, 192, 255, 0.4) 89.33%,
                        rgba(149, 192, 255, 0) 100%
                    )
                    no-repeat;
            background-size: cover;
        }

        h2 {
            @media (min-width: $mobile) {
                margin-bottom: 10px;
            }
            @media (min-width: $tablet-sm) {
                margin-bottom: 26px;
            }
            @media (min-width: 1536px) {
                padding-left: 160px;
            }
            @media (min-width: $desktop-lg) {
                padding-left: 0;
            }
        }
    }

    &_items {
        display: flex;
        flex-direction: column;

        @media (min-width: 1536px) {
            padding: 0 160px;
        }
        @media (min-width: $desktop-lg) {
            padding: 0;
        }
    }

    &_item {
        padding: 10px 0;

        @media (min-width: $mobile-sm) {
            width: 315px;
        }
        @media (min-width: $mobile) {
            padding: 15px 0;
            width: 365px;
        }
        @media (min-width: $mobile-lg) {
            padding: 20px 0;
            width: 525px;
        }
        @media (min-width: $tablet-sm) {
            width: 685px;
            min-height: 415px;
        }
        @media (min-width: $desktop-sm) {
            width: 710px;
            min-height: 425px;
        }
        @media (min-width: $desktop-lg) {
            width: 1080px;
            min-height: 625px;
        }

        &:nth-child(2) {
            align-self: flex-end;
        }

        &:not(:last-child) {
            @media (min-width: $mobile-lg) {
                margin-bottom: 60px;
            }
            @media (min-width: $tablet-sm) {
                margin-bottom: 80px;
            }
            @media (min-width: $desktop-lg) {
                margin-bottom: 160px;
            }
        }
    }

    &_item-img-left {
        display: flex;
        justify-content: flex-end;
    }

    &_item-img {
        width: 264px;
        height: 204px;

        @media (min-width: $mobile) {
            width: 314px;
            height: 238px;
        }
        @media (min-width: $mobile-lg) {
            width: 380px;
            height: 288px;
        }
        @media (min-width: $tablet-sm) {
            width: 494px;
            height: 374px;
        }
        @media (min-width: $desktop-sm) {
            width: 509px;
            height: 384px;
        }
        @media (min-width: $desktop-lg) {
            width: 774px;
            height: 584px;
        }
    }

    &_item-description {
        display: flex;
        margin-top: -85px;

        @media (min-width: $mobile-sm) {
            margin-top: -110px;
        }
        @media (min-width: $mobile-lg) {
            margin-top: -284px;
        }
        @media (min-width: $tablet-sm) {
            margin-top: -370px;
        }
        @media (min-width: $desktop-lg) {
            margin-top: -576px;
        }

        &.left {
            justify-content: left;
        }
        &.right {
            justify-content: right;
        }
    }

    &_item-card {
        position: relative;
        font-size: 14px;
        line-height: 130%;

        @media (min-width: $tablet-sm) {
            font-size: 16px;
        }
        @media (min-width: $desktop-lg) {
            font-size: 24px;
        }
    }

    &_text-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        width: 103px;
        height: 176px;
        transform: rotate(-7deg);

        @media (min-width: $tablet-sm) {
            width: 123px;
            height: 200px;
        }
        @media (min-width: $desktop-sm) {
            width: 130px;
        }
        @media (min-width: $desktop-lg) {
            width: 190px;
            height: 315px;
        }

        &.card1 {
            top: 68px;
            left: 28px;

            @media (min-width: $mobile) {
                top: 68px;
            }
            @media (min-width: $tablet-sm) {
                top: 80px;
                left: 31px;
            }
            @media (min-width: $desktop-sm) {
                top: 87px;
                left: 32px;
            }
            @media (min-width: $desktop-lg) {
                top: 130px;
                left: 51px;
            }
        }

        &.card2 {
            top: 72px;
            left: 70px;
            transform: rotate(7.5deg);

            @media (min-width: $mobile) {
                left: 68px;
            }
            @media (min-width: $mobile-lg) {
                top: 74px;
                left: 69px;
            }
            @media (min-width: $tablet-sm) {
                top: 90px;
                left: 77px;
            }
            @media (min-width: $desktop-sm) {
                top: 92px;
                left: 80px;
            }
            @media (min-width: $desktop-lg) {
                top: 142px;
                left: 127px;
            }
        }

        &.card3 {
            top: 67px;
            left: 28px;

            @media (min-width: $mobile) {
                top: 68px;
                left: 28px;
            }
            @media (min-width: $tablet-sm) {
                top: 82px;
                left: 31px;
            }
            @media (min-width: $desktop-sm) {
                top: 87px;
                left: 32px;
            }
            @media (min-width: $desktop-lg) {
                top: 131px;
                left: 52px;
            }
        }
    }

    &_card-img {
        width: 202px;
        height: 266px;

        @media (min-width: $tablet-sm) {
            width: 236px;
            height: 311px;
        }
        @media (min-width: $desktop-sm) {
            width: 244px;
            height: 319px;
        }
        @media (min-width: $desktop-lg) {
            width: 373px;
            height: 488px;
        }
    }
}

.info_block {
    font-family: 'Paytone One', sans-serif;
    font-size: 24px;
    font-weight: 700;

    @media (min-width: $tablet-sm) {
        font-size: 30px;
    }
}
