@import '../../../../styles/variables';

.whyfun {
    &_video {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 45px;

        @media (min-width: $mobile) {
            margin-bottom: 50px;
        }
        @media (min-width: $tablet-sm) {
            margin-bottom: 65px;
        }
        @media (min-width: $desktop-sm) {
            margin-bottom: 85px;
        }
        @media (min-width: $desktop-lg) {
            margin-bottom: 0;
        }

        &__bg {
            position: absolute;
            background-image: url('../../../../assets/whyfun_bg-360.svg');
            background-position: initial;
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            height: 100%;

            @media (min-width: $mobile) {
                width: 110%;
                background-image: url('../../../../assets/whyfun_bg-414.svg');
            }
            @media (min-width: $mobile-lg) {
                top: 75px;
                width: 100%;
                background-image: url('../../../../assets/whyfun_bg-576.svg');
            }
            @media (min-width: $tablet-sm) {
                background-image: url('../../../../assets/whyfun_bg-768.svg');
            }
            @media (min-width: $desktop-sm) {
                background-image: url('../../../../assets/whyfun_bg-1024.svg');
            }
            @media (min-width: 1200px) {
                top: -140px;
                left: -300px;
                width: 696px;
                height: 1115px;
                background-image: url('../../../../assets/whyfun_bg-1920.svg');
            }
        }

        &__wrapper {
            position: relative;
            padding-top: 30px;

            @media (min-width: 1200px) {
                margin-top: 60px;
                margin-left: 80px;
            }
            @media (min-width: 1440px) {
                margin-left: 0;
            }
            @media (min-width: 1536px) {
                margin-left: 80px;
            }
            @media (min-width: $desktop-lg) {
                margin-top: 0;
                margin-left: 0;
                padding-top: 60px;
                padding-left: 170px;
            }
        }

        &__phone {
            width: 211px;
            height: 426px;
            transform: rotate(-6.5deg);

            @media (min-width: $mobile) {
                width: 246px;
                height: 497px;
            }
            @media (min-width: $mobile-lg) {
                width: 296px;
                height: 596px;
            }
            @media (min-width: $desktop-lg) {
                width: 347px;
                height: 702px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .animation-wrapper {
            position: absolute;
            top: 40px;
            left: 12px;
            width: 186px;
            height: 404px;
            border-radius: 25px;
            z-index: 1;
            transform: rotate(-6.5deg);
            overflow: hidden;

            @media (min-width: $mobile) {
                top: 43px;
                left: 15px;
                width: 216px;
                height: 470px;
                border-radius: 27px;
            }
            @media (min-width: $mobile-lg) {
                top: 45px;
                left: 18px;
                width: 260px;
                height: 567px;
                border-radius: 35px;
            }
            @media (min-width: $desktop-lg) {
                top: 78px;
                left: 190px;
                width: 306px;
                height: 664px;
                border-radius: 39px;
            }

            .animation {
                max-width: none;
                width: 100%;
                height: 100%;
            }
        }
    }

    &_main {
        @media (min-width: 1200px) {
            display: flex;
            justify-content: space-between;
        }
        @media (min-width: 1536px) {
            padding: 0 60px;
        }
        @media (min-width: $desktop-lg) {
            padding: 0;
        }
    }

    &_items {
        @media (min-width: 1200px) {
            max-width: 630px;
        }
        @media (min-width: 1440px) {
            max-width: 725px;
        }
    }

    &_title,
    &_item {
        margin-bottom: 24px;

        @media (min-width: $mobile-lg) {
            margin-bottom: 32px;
        }
        @media (min-width: $desktop-lg) {
            margin-bottom: 30px;
        }
    }

    &_item {
        h2 {
            margin-bottom: 5px;
        }
    }
}

.info_block {
    font-family: 'Paytone One', sans-serif;
    font-size: 24px;
    font-weight: 700;

    @media (min-width: $tablet-sm) {
        font-size: 30px;
    }
}
