.loader-container {
    width: 100%;
    height: 100%;

    &.overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;

        .loading-percentage {
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 500;
            line-height: 25px;
        }
    }

    .spinner {
        position: relative;
        top: calc(50% - 30px);
        left: 15px;
        width: 60px;
        height: 60px;
        margin: 0 auto;
        animation: loadingI 2s linear infinite;

        &.overlay {
            top: initial;
            left: initial;
        }

        .bubble-1,
        .bubble-2 {
            position: absolute;
            top: 0;
            width: 35px;
            height: 35px;
            border-radius: 100%;
            animation: bounce 2s ease-in-out infinite;
        }

        .bubble-2 {
            top: auto;
            bottom: 0;
            animation-delay: -1s;
        }
    }
}

@keyframes loadingI {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
