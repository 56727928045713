// text colors
$main-text: #043192;

// other variables
$btn-gradient: linear-gradient(180deg, #e9d906 0%, #e96706 100%);
$btn-shadow: 0px 9.293916702270508px 6.195943832397461px
    rgba(130.16, 156.71, 208.25, 0.38);
$btn-shadow-hover:
    0px -5.91111px 1.47778px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 4px 0px #ffe54e inset,
    0px 12px 8px 0px rgba(130, 157, 208, 0.38);
$btn-shadow-pressed: 0px 6px 4px 0px rgba(169, 87, 6, 0.65) inset;

// media
$mobile-sm: 360px;
$mobile: 414px;
$mobile-lg: 576px;
$tablet-sm: 768px;
$tablet: 962px;
$desktop-sm: 1024px;
$desktop-lg: 1920px;

// color bar background
$bg-bar-1: #002167;
$bg-bar-2: #000e2c;

// loading bar
$bg-loading1: #b0f462;
$bg-loading2: #c5e1a4;
