@import '../../../../styles/variables';

.hero {
    overflow: hidden;

    &_container {
        text-align: center;
        background: url('../../../../assets/hero_mainbox-360.jpg') no-repeat;
        background-size: 100% auto;

        @media (min-width: $mobile) {
            background: url('../../../../assets/hero_mainbox-414.jpg') no-repeat;
            background-size: 100% auto;
        }
        @media (min-width: $mobile-lg) {
            background: url('../../../../assets/hero_mainbox-576.jpg') no-repeat;
            background-size: 100% auto;
        }
        @media (min-width: $tablet-sm) {
            background: url('../../../../assets/hero_mainbox-768.jpg') no-repeat;
            background-size: 100% auto;
        }
        @media (min-width: $desktop-sm) {
            background: url('../../../../assets/hero_mainbox-1024.jpg')
                no-repeat;
            background-size: 100% auto;
        }
        @media (min-width: 1440px) {
            background: url('../../../../assets/hero_mainbox-1920.jpg')
                no-repeat;
            background-size: 100% auto;
        }
        @media (min-width: 2560px) {
            background: url('../../../../assets/hero_mainbox-2560.jpg')
                no-repeat;
            background-size: 100% auto;
        }
    }

    &_logo-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 15px;

        @media (min-width: $tablet-sm) {
            padding-top: 25px;
        }
        @media (min-width: $desktop-lg) {
            padding-top: 35px;
        }
    }

    &_logo {
        width: 70px;
        height: 55px;

        @media (min-width: $tablet-sm) {
            width: 82px;
            height: 64px;
        }
        @media (min-width: $desktop-sm) {
            width: 97px;
            height: 76px;
        }
        @media (min-width: $desktop-lg) {
            width: 155px;
            height: 122px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &_main {
        position: relative;
        width: 100%;
        margin-bottom: 20px;

        button {
            margin: 0 auto;
        }
    }

    &_main-box {
        position: relative;
        min-height: 95vw;
        padding: 10vw 20px 0;

        @media (min-width: $mobile-sm) {
            max-width: 300px;
            min-height: 96vw;
            margin: 0 auto;
            padding-top: 13vw;
            padding-right: 0;
            padding-left: 0;
        }
        @media (min-width: 385px) {
            max-width: 330px;
            padding-top: 17vw;
        }
        @media (min-width: $mobile) {
            min-height: 86vw;
            padding-top: 9vw;
        }
        @media (min-width: 500px) {
            max-width: 340px;
            padding-top: 18vw;
        }
        @media (min-width: $mobile-lg) {
            min-height: 57vw;
            padding-top: 4vw;
        }
        @media (min-width: 675px) {
            padding-top: 10vw;
        }
        @media (min-width: $tablet-sm) {
            max-width: 500px;
            min-height: 60vw;
            padding-top: 6vw;
        }
        @media (min-width: 834px) {
            padding-top: 8vw;
        }
        @media (min-width: $tablet) {
            padding-top: 12vw;
        }
        @media (min-width: $desktop-sm) {
            max-width: 650px;
            min-height: 45vw;
            padding-top: 2vw;
        }
        @media (min-width: 1200px) {
            padding-top: 6.5vw;
        }
        @media (min-width: 1440px) {
            min-height: 49vw;
            padding-top: 9vw;
        }
        @media (min-width: 1440px) {
            max-width: 820px;
            min-height: 46vw;
            padding-top: 7vw;
        }
        @media (min-width: $desktop-lg) {
            max-width: 1035px;
        }
        @media (min-width: 2560px) {
            min-height: 37vw;
            padding-top: 5vw;
        }
    }

    &_main-img {
        display: none;

        @media (min-width: $mobile-lg) {
            position: absolute;
            display: initial;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &_main-ball {
        @media (min-width: $mobile-lg) {
            width: 18vw;
            height: 47vw;
            right: 0;
            bottom: -10px;
        }
        @media (min-width: $tablet-sm) {
            height: 46vw;
            bottom: 10px;
        }
        @media (min-width: $desktop-sm) {
            width: 20vw;
            height: 42vw;
            bottom: 20px;
        }
        @media (min-width: $desktop-lg) {
            width: 19.3vw;
            height: 39.5vw;
            bottom: 85px;
        }
        @media (min-width: 2100px) {
            width: 17.4vw;
            height: 35.5vw;
        }
    }

    &_main-footbolist {
        @media (min-width: $mobile-lg) {
            width: 20vw;
            height: 30vw;
            left: -20vw;
            bottom: 7px;
        }
        @media (min-width: 675px) {
            left: -27vw;
        }
        @media (min-width: $tablet-sm) {
            height: 31vw;
            left: -17vw;
            bottom: 20px;
        }
        @media (min-width: $tablet) {
            left: -23vw;
            bottom: 0;
        }
        @media (min-width: $desktop-sm) {
            width: 17.5vw;
            height: 27vw;
            left: -12vw;
        }
        @media (min-width: $desktop-sm) {
            width: 17vw;
            height: 26vw;
            left: -15vw;
            bottom: 10px;
        }
        @media (min-width: 2100px) {
            width: 15.3vw;
            height: 23.4vw;
            left: -16.5vw;
            bottom: 0;
        }
    }

    &_main-title {
        max-width: 270px;
        margin: 0 auto 8px;

        @media (min-width: $mobile) {
            max-width: none;
        }
        @media (min-width: $desktop-sm) {
            max-width: 555px;
        }
        @media (min-width: 1440px) {
            max-width: none;
            margin: 0 auto 14px;
        }
    }

    &_main-subtitle {
        font-family: 'Andika', sans-serif;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 12px;

        @media (min-width: $mobile-lg) {
            margin-bottom: 14px;
        }
        @media (min-width: $tablet-sm) {
            max-width: 430px;
            margin: 0 auto 22px;
            font-size: 18px;
            line-height: 24px;
        }
        @media (min-width: $desktop-sm) {
            max-width: none;
            margin: 0 auto 16px;
            font-size: 20px;
            line-height: normal;
        }
        @media (min-width: 1280px) {
            margin: 0 auto 44px;
        }
        @media (min-width: 1440px) {
            font-size: 28px;
            margin: 0 auto 34px;
        }
        @media (min-width: 2100px) {
            margin: 0 auto 100px;
        }
        @media (min-width: 2560px) {
            margin: 0 auto 74px;
        }
    }

    &_policies {
        display: flex;
        justify-content: space-between;
        margin-top: 18px;

        @media (min-width: $mobile) {
            margin-top: 22px;
        }
        @media (min-width: $mobile-lg) {
            margin-top: 11px;
        }
        @media (min-width: $tablet-sm) {
            max-width: 380px;
            margin: 20px auto 0;
        }
        @media (min-width: $desktop-sm) {
            margin: 42px auto 0;
        }
        @media (min-width: $desktop-sm) {
            max-width: 560px;
        }
        @media (min-width: 1280px) {
            max-width: 600px;
            margin: 64px auto 0;
        }
        @media (min-width: 1440px) {
            max-width: none;
        }
        @media (min-width: $desktop-lg) {
            margin: 146px auto 0;
        }
        @media (min-width: 2560px) {
            margin: 124px auto 0;
        }
    }

    &_policy {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-width: 95px;

        @media (min-width: $tablet-sm) {
            max-width: 110px;
        }
        @media (min-width: $desktop-sm) {
            flex-direction: row;
            align-items: center;
            max-width: 176px;
        }
        @media (min-width: 1440px) {
            max-width: 265px;
        }
        @media (min-width: $desktop-lg) {
            max-width: 326px;
        }
    }

    &_policy-img {
        width: 35px;
        height: 35px;

        @media (min-width: $desktop-sm) {
            width: 40px;
            height: 40px;
            min-width: 41px;
            margin-right: 16px;
        }
        @media (min-width: 1440px) {
            width: 74px;
            height: 74px;
            min-width: 74px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &_policy-text {
        color: #fff;
        text-align: center;
        font-family: 'Archivo', sans-serif;
        font-size: 10px;
        font-weight: 800;
        line-height: 100%;
        letter-spacing: 0.9px;
        text-shadow: 0 1.013222098350525px 2.02644419670105px
            rgba(4, 49, 146, 0.4);

        @media (min-width: $tablet-sm) {
            font-size: 12px;
            text-align: center;
        }
        @media (min-width: 1440px) {
            font-size: 18px;
        }
        @media (min-width: $desktop-lg) {
            font-size: 20px;
        }

        em {
            font-style: normal;
        }
    }

    &_advantages {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;

        @media (min-width: $desktop-sm) {
            padding: 0 20px;
        }
        @media (min-width: 1200px) {
            justify-content: space-between;
        }
        @media (min-width: 1536px) {
            justify-content: space-evenly;
            padding: 0 50px;
        }
        @media (min-width: 1440px) {
            justify-content: space-between;
            padding: 0;
        }
    }

    &_advantage-item {
        position: relative;
        display: flex;
        justify-content: center;
        width: 167px;
        height: 170px;
        margin-bottom: 5px;

        @media (min-width: $mobile) {
            width: 195px;
            height: 190px;
            margin-bottom: 15px;
        }
        @media (min-width: $mobile-lg) {
            width: 273px;
            height: 270px;
            margin-bottom: 25px;
        }
        @media (min-width: $tablet-sm) {
            width: 355px;
            height: 342px;
        }
        @media (min-width: $desktop-sm) {
            width: 240px;
            height: 235px;
        }
        @media (min-width: 1440px) {
            width: 280px;
            height: 280px;
        }
        @media (min-width: 1536px) {
            width: 360px;
            height: 350px;
        }
    }

    &_advantage-icon {
        position: absolute;
        width: 92%;
        z-index: 2;
    }

    &_advantage-box {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
    }

    .hero_advantage-people {
        align-self: center;
        max-width: 125px;
        padding-top: 70px;
        font-size: 16px;

        @media (min-width: $mobile) {
            padding-top: 80px;
        }

        @media (min-width: $mobile-lg) {
            max-width: 180px;
            font-size: 24px;
            padding-top: 110px;
        }

        @media (min-width: $tablet-sm) {
            font-size: 28px;
            padding-top: 140px;
        }

        @media (min-width: $tablet) {
            max-width: 240px;
            padding-top: 140px;
        }

        @media (min-width: $desktop-sm) {
            max-width: 152px;
            font-size: 20px;
            padding-top: 100px;
        }

        @media (min-width: 1440px) {
            max-width: 240px;
            font-size: 24px;
            padding-top: 115px;
        }
    }

    &_advantage-text {
        align-self: center;
        max-width: 125px;
        padding-top: 55px;
        font-size: 16px;
        font-family: 'Paytone One', sans-serif;
        line-height: 100%;
        z-index: 3;

        @media (min-width: $mobile) {
            padding-top: 70px;
        }
        @media (min-width: $mobile-lg) {
            max-width: 190px;
            padding-top: 90px;
            font-size: 24px;
        }
        @media (min-width: $tablet-sm) {
            max-width: 240px;
            padding-top: 110px;
            font-size: 28px;
        }
        @media (min-width: $desktop-sm) {
            max-width: 160px;
            padding-top: 85px;
            font-size: 20px;
        }
        @media (min-width: 1440px) {
            max-width: 215px;
            padding-top: 110px;
            font-size: 24px;
        }
        @media (min-width: 1536px) {
            max-width: 240px;
            padding-top: 130px;
        }
    }
}
