@import '../../../../styles/variables';

.game_description {
    line-height: 1.5;

    p {
        font-size: 24px;
        line-height: 1.5;
        text-align: start;

        @media (max-width: $mobile-lg) {
            font-size: 18px;
        }
    }

    .game_description_title {
        margin-bottom: 16px;
    }

    .game_details {
        margin-bottom: 30px;
    }

    .game_location_info {
        margin-bottom: 30px;
    }
}

.game_description_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1304px;
    padding: calc(40px + (100 - 40) * ((100vw - 320px) / (1920 - 320))) 24px;
    margin: 0 auto;
    column-gap: 100px;

    @media (max-width: $desktop-sm) {
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 32px;
    }

    .game_description_list {
        margin-bottom: 30px;
        text-align: start;
        max-width: 715px;

        li {
            font-size: 24px;
            list-style-type: disc;
            margin-left: 50px;

            @media (max-width: $mobile-lg) {
                font-size: 18px;
            }
        }
    }
}

.game_description_logo {
    width: 339px;
    height: 304px;

    @media (max-width: $mobile) {
        width: 300px;
        height: 268px;
    }

    @media (max-width: $mobile-sm) {
        width: 270px;
        height: 241px;
    }
}

//---------------------------------------------------------

.game_details_wrapper {
    width: 100%;
    background: rgba(128, 180, 255, 0.29);
}

.game_details_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 25px;
    max-width: 1503px;
    margin: 0 auto;
    padding: 40px 24px;

    @media (max-width: $desktop-sm) {
        flex-direction: column-reverse;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 32px;
    }

    .game_details {
        max-width: 715px;
    }

    .game_details_logo {
        min-width: calc(272px + (640 - 300) * ((100vw - 320px) / (1920 - 320)));
        min-height: calc(
            241px + (495 - 241) * ((100vw - 320px) / (1920 - 320))
        );
    }
}

//---------------------------------------------------------
.game_location_info_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(40px + (100 - 40) * ((100vw - 320px) / (1920 - 320))) 24px;
    max-width: 1369px;
    margin: 0 auto;
    column-gap: 42px;

    @media (max-width: $desktop-sm) {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 32px;
    }

    .game_location_info_logo {
        width: 500px;
        height: auto;
    }

    .game_location_info_text {
        max-width: 715px;
    }
}
